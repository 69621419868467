<script>
export default {
  name: "MyRedeems",

  data() {
    return {
      pastRedeems: [],
    };
  },

  mounted() {
    this.fetchRedeems();
  },


  methods: {
    fetchRedeems() {
      this.axios
          .get("/quotes/past-redeems")
          .then(({ data: { pastRedeems } }) => {
            this.pastRedeems = pastRedeems;
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
}
</script>

<template>
  <div class="mt-8">
    <h1 class="text-2xl font-bold">Mis canjes</h1>

    <div>
      <table
          class="uk-table uk-table-divider uk-table-hover uk-table-middle uk-visible@s"
          v-if="pastRedeems.length !== 0"
      >
        <thead>
        <th width="30%">Fecha de compra</th>
        <th class="uk-text-center" width="10%">Código</th>
        <th class="uk-text-center" width="20%">Cantidad de productos</th>
        <th class="uk-text-center" width="20%">Total</th>
        <th width="20%"></th>
        </thead>
        <tbody>
        <tr v-for="pastRedeem in pastRedeems" :key="pastRedeem.id">
          <td>
            {{ formatDatetime(pastRedeem.created_at) }}
          </td>
          <td class="uk-text-center">
            {{ pastRedeem.code }}
          </td>
          <td class="uk-text-center">
            {{
              pastRedeem.redeem_items.reduce(
                  (accum, item) => accum + item.quantity,
                  0
              )
            }}
          </td>
          <td
              v-if="pastRedeem.is_rollback"
              class="flex items-center justify-center"
          >
            <div
                class="bg-red-500 text-white rounded-full px-4 py-2 w-32 text-center"
            >
              Cancelada
            </div>
          </td>
          <td v-else class="uk-text-center">
            {{
              formatThousands(
                  pastRedeem.redeem_items.reduce(
                      (accum, item) => accum + item.quantity * item.point_price,
                      0
                  )
              )
            }}
            {{ pointsName }}
          </td>
          <td>
            <router-link
                :to="'/redeem/' + pastRedeem.id"
                tag="button"
                :disabled="false"
                class="uk-button uk-button-primary uk-border-rounded"
            >
              Ver compra
            </router-link>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<style scoped>

</style>